.line-divisor {
    width: 100%;
    height: 1px;
    min-height: 1px;
    background-color: #d8d8da;
    // margin-top: 12px;
    // margin-bottom: 27px;
}

.gap-8 {
    gap: 8px;
}

.gap-10 {
    gap: 10px;
}

.gap-12 {
    gap: 12px;
}

.gap-16 {
    gap: 16px;
}

.gap-24 {
    gap: 24px;
}