html, body {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
}

.content {
  width: 100% !important;
  margin: 0 auto;
  padding: 0 10% 0 !important;
}