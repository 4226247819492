$primary: #c21b17;
$primary-bg: #f5f5f5;
$footer-bg: #343a40;
$white: #ffffff;
$black: #393939;
$green: #2e7f65;
$black-30-opacity: rgba(56, 56, 56, 0.3);
$link: #0048d9;
$pagination-active: #f85c2a;
$error: #cf3c4f;
$gray: #333;

$text-default: #20252b;
$text-accent: #e0493b;
$text-muted: #6e6f71;
$border-muted: #d4d3d4;

// INPUT
$input-bg: #f8fafc;
$input-placeholder: #979797;
$input-border: #5a5d62;
$input-design-rounded: 60px;
$input-design-normal: 4px;
$input-boxed-clr: #c5281c;
$checkbox-selected: #11a9bf;

// ROLES
$admin-role: #31135e;
$contributor-plus: #2dd36f;
$contributor: #ffc409;
$viewer: #dadada;

$small-shadow: 0px 0px 2.4000000953674316px 0px #00000040;
$small-shifted-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);

$grey-bg: #f2f2f2;
$grey-4: #6e6f71;
