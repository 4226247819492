.custom-select {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  max-width: 100% !important;
  text-indent: 1px;

  &.select-searchbar-pagination {
    width: 81px;
    min-width: 81px;
    min-width: 81px;

    .ng-select-container {
      border-radius: 4px;
      border: 1px solid #6e6f71;
      background: #fff;
      font-size: 14px;
      color: #3d4047;
    }
  }

  // min-width: 290px;
  .ng-option {
    p {
      font-size: 16px !important;
      text-align: left;
      line-height: 19px;
      font-weight: 400;
      color: #20252b;
    }
  }

  .ng-value .ng-star-inserted {
    min-height: 48px !important;
    max-height: 48px !important;
    height: 48px !important;
    z-index: 999;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
      font-size: 16px !important;
    }
  }

  .ng-clear-wrapper {
    display: none;
  }

  .ng-select {
    &.ng-select-opened {
      .ng-select-container {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 0px;
      }

      .ng-arrow-wrapper {
        transform: rotate(-45deg);
        top: 6px;
      }
    }

    .ng-has-value .ng-placeholder {
      display: none;
    }

    .ng-select-container .ng-value-container {
      padding: 0;

      .ng-input {
        top: 0;
        height: 100%;
        cursor: pointer;
      }

      .ng-input>input {
        height: auto !important;
        min-height: 100% !important;
        left: 48px;
        padding: 0px 48px !important;
        cursor: pointer;
      }

      .ng-placeholder {
        top: 0px;
        padding-bottom: 0;
        font-weight: 400;
        color: #5a5d62;
        font-size: 16px;
      }
    }

    .ng-arrow-wrapper {
      .ng-arrow {
        display: none;
      }

      content: " " !important;
      display: block !important;
      position: absolute !important;
      right: 18px;
      top: -1px;
      width: 12px;
      height: 12px;
      margin-top: 15px;
      border-style: solid;
      border-width: 1.5px;
      border-color: #20252b #20252b transparent transparent !important;
      transform: rotate(135deg);
    }

    &.ng-selected-hide-selected-options {
      .ng-select-container .ng-value-container {
        .ng-value {
          display: none;
        }

        .ng-input {
          padding-left: 3px !important;
        }
      }
    }

    nga-icon {
      fill: #fff;
      width: 23px;
      height: 23px;

      &.icon {
        fill: #000 !important;
      }
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #cccccc;
    }

    &::-webkit-scrollbar:horizontal {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      border: 1px #d3d3d3 solid;
      border-radius: 10px;
      -webkit-box-shadow: 0 0 6px #c8c8c8 inset;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #333333;
      border: 1px solid #000000;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #333333;
      border: 1px solid #333333;
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: #333333;
      border: 1px solid #333333;
    }

    .ng-option {
      padding: 18px 16px 18px 16px;
      height: 56px;

      .highlighted {
        font-weight: bold;
        text-decoration: underline;
      }

      &.ng-option-selected {

        h5,
        span {
          color: #fff !important;
          font-weight: 500 !important;
        }

        .select-options-card .card-body p {
          // color: #fff;
        }
      }

      .select-options-card {
        .card-body {
          flex: 1 1 auto;
          padding: 0;

          p {
            font-size: 16px !important;
            text-align: left;
            line-height: 19px;
            font-weight: 400;
            color: #20252b;
          }

          img {
            margin: 0 !important;
          }

          .card-subtitle {
            font-weight: 500;
            line-height: 16px;
            margin: 0px auto;
            width: 100%;
            color: #333;
            font-size: 12px;
            margin-left: 10px;

            &.cid {
              font-weight: 700;
            }

            &.italic {
              font-style: italic;
            }

            .text-muted {
              color: #6c757d !important;
            }
          }

          .card-title {
            color: red;
            font-weight: bold;
            line-height: 19px;
            margin: 0px auto;
            width: 100%;
            font-size: 14px;
            margin-left: 10px;

            .text-muted {
              color: #6c757d !important;
            }
          }

          .you-are-invited-option-text {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #000000;
            text-transform: uppercase;
          }

          .title {
            font-size: 18px;
            font-weight: 600;
            width: 100%;
            margin: 0;
            white-space: break-spaces;
          }

          .subtitle {
            font-size: 16px;
            width: 100%;
            white-space: break-spaces;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    background-color: #f2f2f2 !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background-color: #f2f2f2 !important;

    p {
      font-weight: 700;
    }
  }

  .ng-select-disabled {
    .ng-select-container {
      background: #F9F8F8;
      border: 1px solid #5A5D62;
      color: #665555;
    }

    .ng-arrow-wrapper {
      border-color: #8C8C8C #8C8C8C transparent transparent !important;
    }
  }

  .ng-select-container {
    display: block;
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 0 16px 0 16px;
    background-image: none;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
    line-height: 48px;
    border-radius: 4px;
    border: 1px solid #5a5d62;
    background: #fff;
    font-size: 16px;
    color: #20252b;
    font-weight: 400;
    -webkit-transition: border-color ease-in-out 0.15s,
      -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  }
}

$ng-select-highlight: #007eff !default;
$ng-select-primary-text: #333 !default;
$ng-select-disabled-text: #f9f9f9 !default;
$ng-select-border: #ccc !default;
$ng-select-border-radius: 4px !default;
$ng-select-bg: #ffffff !default;
$ng-select-selected: lighten($ng-select-highlight, 46) !default;
$ng-select-marked: lighten($ng-select-highlight, 48) !default;
$ng-select-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
  0 0 0 3px rgba(0, 126, 255, 0.1) !default;
$ng-select-placeholder: lighten($ng-select-primary-text, 40) !default;
$ng-select-height: 36px !default;
$ng-select-value-padding-left: 10px !default;
$ng-select-value-font-size: 0.9em !default;

.ng-dropdown-panel {
  background-color: $ng-select-bg;
  border: 1px solid #5a5d62;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  left: 0;

  &.ng-select-bottom {
    top: 100%;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: var(--white);
    margin-top: 0px;
  }

  &.ng-select-top {
    bottom: 100%;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-color: lighten($ng-select-border, 10);
    margin-bottom: -1px;

    .ng-dropdown-panel-items {
      .ng-option {
        &:first-child {
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        }
      }
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $ng-select-border;
    padding: 5px 7px;
  }

  .ng-dropdown-footer {
    border-top: 0px !important;
    padding: 0px !important;
  }

  .ng-dropdown-panel-items {
    .ng-optgroup {
      user-select: none;
      padding: 8px 10px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
      cursor: pointer;

      &.ng-option-disabled {
        cursor: default;
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
      }

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        background-color: $ng-select-selected;
        font-weight: 600;
      }
    }

    .ng-option {
      background-color: $ng-select-bg;
      color: rgba(0, 0, 0, 0.87);
      padding: 8px 10px;

      &.ng-option-selected,
      &.ng-option-selected.ng-option-marked {
        color: $ng-select-primary-text;
        background-color: $ng-select-selected;

        .ng-option-label {
          font-weight: 600;
        }
      }

      &.ng-option-marked {
        background-color: $ng-select-marked;
        color: $ng-select-primary-text;
      }

      &.ng-option-disabled {
        color: lighten($ng-select-primary-text, 60);
      }

      &.ng-option-child {
        padding-left: 22px;
      }

      .ng-tag-label {
        font-size: 80%;
        font-weight: 400;
        padding-right: 5px;
      }
    }
  }
}

.ng-input {
  cursor: default !important;
  background-color: #f7f7f7 !important;
  border: 0;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  border-radius: 2px !important;
}