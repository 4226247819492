@forward "./class/container";
@forward "./class/simple-pagination";
@forward "./class/modal";
@forward "./responsive/responsive";
@forward "./colors/colors";
@forward "./variables-bootstrap.scss";
@forward "./components/select";
@forward "utils.scss";

* {
  &:focus-visible {
    outline: solid;
    outline-color: #f2c6c7;
  }
}