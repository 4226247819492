@use "/src/assets/scss/index" as *;
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
// Per cambiare il tema della libreria di alloy, basterà modificare il file di tema importato dalla libreria
@import 'alloy-design-system/dist/ng-alloy/src/lib/styles/brands/brand-generali-ng/theme.scss';


p {
  margin: 0
}

.text-align-end {
  text-align: end;
}

* {
  font-family: 'Roboto';
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.fit-content {
  width: fit-content;
}

.page-title {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-32 {
  padding-bottom: 32px;
}

.pb-36 {
  padding-bottom: 36px;
}