$primary: #C21B17;
$primary-bg: #F5F5F5;
$footer-bg: #343A40;
$white: #FFFFFF;
$black: #393939;
$green: #2e7f65;
$black-30-opacity: rgba(56, 56, 56, 0.3);
$link: #0048D9;
$pagination-active: #F85C2A;
$error: #CF3C4F;
$gray: #333;

$text-default: #20252b;
$text-accent: #E0493B;
$text-muted: #6E6F71;
$border-muted: #D4D3D4;

// INPUT
$input-bg: #F8FAFC;
$input-placeholder: #979797;
$input-border: #5A5D62;
$input-design-rounded: 60px;
$input-design-normal: 4px;
$input-boxed-clr: #C5281C;
$checkbox-selected: #11A9BF;


$small-shadow: 0px 0px 2.4000000953674316px 0px #00000040;
$small-shifted-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);

$grey-bg: #F2F2F2;
$grey-4: #6E6F71;